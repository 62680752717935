import React, { useState, useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Navigation } from "./components/navigation/navigation";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";
import { Loader } from "./components/loader/Loader"; // Import Loader
import AppRoutes from './AppRoutes'; // Import the AppRoutes component
import { Footer } from "./components/footer/footer"; // Import Footer

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState(null); // Start as null
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    // Simulate a data fetching delay
    setTimeout(() => {
      setLandingPageData(JsonData); // Load data from JSON file
      setLoading(false); // Set loading to false once data is loaded
    }, 2000); // Simulated 2-second delay
  }, []);

  if (loading) {
    return <Loader />; // Show the loader while data is loading
  }

  return (
    <Router basename="/">
      <div>
        <Navigation />
        <AppRoutes landingPageData={landingPageData} /> {/* Use AppRoutes for routing */}
        <Footer />
      </div>
    </Router>
  );
};

export default App;
