import React, { useEffect, useState, useRef } from 'react';
import { useParams, Link } from 'react-router-dom';
import './serviceDetail.css'; // Ensure the correct path to your CSS

export const ServiceDetail = ({ data, topImage }) => {
  const { serviceId } = useParams();
  const service = data?.find(s => s.id === parseInt(serviceId, 10));
  const [currentIndex, setCurrentIndex] = useState(0); // Track current card index
  const [showCards, setShowCards] = useState(false);
  const cardContainerRef = useRef(null);
  const relatedServices = data.filter(s => s.category === service.category && s.id !== service.id);
  const numCards = relatedServices.length;

  // Show cards after a short delay
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowCards(true);
    }, 100);
    return () => clearTimeout(timer);
  }, []);

  // Auto-scroll every 5 seconds
  useEffect(() => {
    const autoScroll = setInterval(() => {
      handleNext();
    }, 5000); // Adjust for slower scrolling
    return () => clearInterval(autoScroll);
  }, [currentIndex]);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % numCards); // Loop back to start
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + numCards) % numCards); // Loop to last card
  };

  const handleDotClick = (index) => {
    setCurrentIndex(index); // Set the index based on dot click
  };

  // Scroll the container when currentIndex changes
  useEffect(() => {
    if (cardContainerRef.current) {
      cardContainerRef.current.scrollTo({
        left: cardContainerRef.current.offsetWidth * currentIndex,
        behavior: 'smooth',
      });
    }
  }, [currentIndex]);

  if (!service) {
    return (
      <div className="service-not-found">
        <h2>Service not found</h2>
        <p>We couldn't find the service you're looking for.</p>
        <Link to="/services">Back to Services</Link>
      </div>
    );
  }

  return (
    <div className="service-detail">
      {/* Top Image with Overlay */}
      <div
        className="top-image"
        style={{ backgroundImage: `url(${topImage || "/img/intro-bg1.jpg"})` }}
      >
        <div className="overlay-text">
          <h1>Service Overview</h1>
          <p>{service.text}</p>
        </div>
      </div>
      <div className="service-content">
        <div className="service-image">
          <img src={service.image} alt={service.name} />
        </div>
        <div className="service-description">
          <h2>{service.name}</h2>
          <p>{service.details}</p>
        </div>
      </div>
      {/* Related Services Section */}
      <div id="related-services" className="text-center">
        <h3>Other Services You May Like</h3>
        <div className="container">
          {/* Scrollable container for cards */}
          <div
            className={`related-services-scroll ${showCards ? "show-cards" : ""}`}
            ref={cardContainerRef}
          >
            {relatedServices.length > 0 ? (
              relatedServices.map((d, index) => (
                <div key={d.id} className="service-card">
                  <img src={d.image} alt={d.name} className="service-card-image" />
                  <div className="service-desc">
                    <h3>{d.name}</h3>
                    <p>{d.text}</p>
                    <Link to={`/service/${d.id}`}>
                      <button>Details</button>
                    </Link>
                  </div>
                </div>
              ))
            ) : (
              <p>No related services available.</p>
            )}
          </div>
        </div>
        {/* Dot Navigation */}
        <div className="dot-navigation">
          {relatedServices.map((_, index) => (
            <span
              key={index}
              className={`dot ${index === currentIndex ? "active" : ""}`}
              onClick={() => handleDotClick(index)}
            ></span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ServiceDetail;
