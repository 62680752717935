// components/Loader/Loader.js
import React from 'react';
import { ThreeDots } from 'react-loader-spinner'; // Import the loader you want
import './Loader.css';


export const Loader = () => {
  return (
    <div className="loader">
      <ThreeDots
        height="100"
        width="100"
        color="#3498db" // Change the color as needed
        ariaLabel="loading"
      />
    </div>
  );
};
