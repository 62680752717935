import React from 'react';
import './SpecializedTech.css';

export const SpecializedTech = () => {
  const techFields = [
    { icon: "💰", title: "FinTech", color: "bg-orange-400" },
    { icon: "🏫", title: "EdTech", color: "bg-blue-500" },
    { icon: "⚕️", title: "MedTech", color: "bg-green-500" },
    { icon: "🌱", title: "AgriTech", color: "bg-yellow-500" },
    { icon: "⚙️", title: "IMS Tech", color: "bg-purple-400" },
    { icon: "🏭", title: "Industrial Tech", color: "bg-indigo-500" },
    { icon: "🛡️", title: "InsureTech", color: "bg-red-500" },
    { icon: "⚡", title: "EnergyTech", color: "bg-teal-500" },
    { icon: "🌍", title: "ESG ", color: "bg-gray-500" },
    { icon: "📊", title: "Project Management", color: "bg-pink-500" },
  ];

  return (
    <div className="specialized-tech-container gradient-bg">
      <h2 className="text-3xl font-bold text-center mb-12 text-gray-800 animate-fadeIn">
        Our Specialized Tech Fields
      </h2>
      <div className="relative max-w-4xl mx-auto min-h-[600px]">
        <div className="hexagon-container">
          {techFields.map((field, index) => (
            <div
              key={index}
              className={`hexagon-wrapper ${
                index === 1 ? 'md:col-start-3' : ''
              } ${index === 4 ? 'md:col-start-2' : ''}`}
            >
              <div
                className={`hexagon ${field.color} text-white flex items-center justify-center text-center p-4 transform transition-all duration-300 hexagon-hover hexagon-pulse`}
              >
                <div className="flex flex-col items-center gap-2">
                  <span className="font-bold text-3xl">{field.icon}</span>
                  <span className="font-semibold text-xl font-bold">{field.title}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SpecializedTech;
