// src/components/careers/Careers.js
import React, { useEffect, useState } from 'react';
import JsonData from '../../data/data.json';
import './careers.css';

export const Careers = () => {
  const [careersData, setCareersData] = useState(null);

  useEffect(() => {
    setCareersData(JsonData.careers); // Accessing the careers data directly from the JSON
  }, []);

  if (!careersData) {
    return <div>Loading...</div>; // Loading state
  }

  return (
    <div className="careers-section">
      <h2>{careersData.title}</h2>
      <p>{careersData.paragraph}</p>
      <h3>Current Job Openings:</h3>
      <ul>
        {careersData.jobOpenings.map((job, index) => (
          <li key={index}>
            <h4>{job.title}</h4>
            <p>{job.description}</p>
            <a href={job.applicationLink} target="_blank" rel="noopener noreferrer">
              Apply Now
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

// No default export needed
