// Header.js
import React, { useEffect, useState } from 'react';
import { TailSpin } from 'react-loader-spinner'; // Importing the loader component
import './header.css'; // Ensure this path is correct
import '@fontsource/roboto-slab'; // Importing the Roboto Slab font

export const Header = (props) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [fadeClass, setFadeClass] = useState('fade active');
  const [isLoading, setIsLoading] = useState(true); // State for managing loader visibility

  const imageClasses = [
    'bg-image-1',
    'bg-image-2',
    'bg-image-3'
  ];

  // Preload images
  useEffect(() => {
    const loadImages = async () => {
      const promises = imageClasses.map(className => {
        return new Promise((resolve) => {
          const img = new Image();
          // Assuming your images are located in the /img/ directory with appropriate filenames
          img.src = `/img/${className.replace('bg-', 'intro-bg')}.jpg`; 
          img.onload = resolve; // Resolve the promise when the image loads
        });
      });

      await Promise.all(promises); // Wait for all images to load
      setIsLoading(false); // Hide loader when all images are loaded
    };

    loadImages();
  }, [imageClasses]);

  // Change background image every 5 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setFadeClass('fade'); // Start fading out
      setTimeout(() => {
        setCurrentImage((prevImage) => (prevImage + 1) % imageClasses.length);
        setFadeClass('fade active'); // Fade back in
      }, 1000); // Matches CSS transition time
    }, 5000); // 5-second interval for image change
    return () => clearInterval(interval);
  }, [imageClasses.length]);

  return (
    <header id="header" className={`intro ${imageClasses[currentImage]}`}>
      {isLoading && (
        <div className="loader">
          <TailSpin height="50" width="50" color="#2251ff" ariaLabel="loading" />
        </div>
      )}
      <div className={`overlay ${fadeClass}`}>
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2 intro-text">
              <h1 style={{ fontFamily: '"Roboto Slab", serif' }}>
                {props.data ? props.data.title : "Loading"}
                <span></span>
              </h1>
              <p>{props.data ? props.data.paragraph : "Loading"}</p>
              <a href="#features" className="btn btn-custom btn-lg page-scroll">
                Learn More
              </a>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
