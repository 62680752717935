import React from "react";
import "./whoweare.css";
import { Contact } from "../contact/contact";

export const WhoWeAre = (props) => {
  return (
    <section>
      <div className="about-1 ">
        <h1>ABOUT TERRY TECHNOLOGIES LIMITED</h1>
        <p>
          Terry Technologies Limited is a leading provider of Information and
          Communication Technology (ICT) solutions in Uganda.
        </p>
      </div>
      <div id="about-2">
        <div class="content-box-lg"></div>
        <div class="container">
          <div class="row">
            <div class="col-md-4">
              <div class="about-item text-center">
                <i class="fa fa-book"></i>
                <h3>MISSION</h3>
                <hr></hr>
                <p>
                  Our mission is to deliver innovative and reliable ICT
                  solutions that empower businesses and organizations in Uganda
                  to achieve their goals and thrive in the digital age.
                </p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="about-item text-center">
                <i class="fa fa-globe"></i>
                <h3>VISION</h3>
                <hr></hr>
                <p>
                  Our vision is to be a leading provider of comprehensive ICT
                  solutions in Uganda, recognized for our commitment to
                  excellence, innovation, and customer satisfaction.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="about-item text-center">
                <i className="fa fa-laptop"></i> <h3>OUR WORK</h3>
                <hr></hr>
                <p>
                  We offer a wide range of ICT services to meet the diverse
                  needs of our clients. These services include: Infrastructure
                  and Network Services, Software Development, IT Consulting and
                  Support, Data Management and Analytics, Digital Transformation
                  Services
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center">
        <div className="container">
          <div className="col-md-8 col-md-offset-2 section-title">
            <h2>Meet the Team</h2>
            <p>
      At Terry Technologies Limited, we pride ourselves on having a dedicated team of professionals committed to delivering the best ICT solutions. Our diverse expertise ensures that we meet the needs of our clients effectively and efficiently.
    </p>
          </div>
          <div id="row">
            {props.data
              ? props.data.map((d, i) => (
                  <div
                    key={`${d.name}-${i}`}
                    className="col-md-3 col-sm-6 team"
                  >
                    <div className="thumbnail">
                      <img src={d.img} alt="..." className="team-img" />
                      <div className="social-icons">
                        <a
                          href={d.twitter}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="fab fa-twitter"></i>
                        </a>
                        <a
                          href={d.linkedin}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="fab fa-linkedin"></i>
                        </a>
                        <a
                          href={d.facebook}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="fab fa-facebook"></i>
                        </a>
                      </div>
                      <div className="caption">
                        <h4>{d.name}</h4>
                        <p>{d.position}</p>
                      </div>
                    </div>
                  </div>
                ))
              : "loading"}
          </div>
        </div>
      </div>
      <Contact />
    </section>
  );
};
