import React from 'react';
import { useNavigate } from 'react-router-dom';
import './services.css';

export const Services = ({ data }) => {
  const navigate = useNavigate();
  const handleServiceClick = (serviceId) => {
    navigate(`/service/${serviceId}`);
  };

  if (!data) {
    return <p>Loading services...</p>; // Loading state
  }

  if (data.length === 0) {
    return <p>No services available at the moment.</p>; // Error handling for empty data
  }

  return (
    <div id="services" className="text-center">
      <div className="image-container">
        <img src="/img/intro-bg1.jpg" alt="Header" className="header-image" />
        <div className="image-overlay">
          <h1>Empowering Your Digital Journey</h1>
          <p>Explore our range of services designed to help you achieve success in the digital world.</p>
        </div>
      </div>
      <div className="container">
        <div className="section-title">
          <h2>Our Services</h2>
          <p>We offer a variety of services to meet your needs. Explore our range of solutions designed to help you succeed.</p>
        </div>
        <div className="row">
          {data.map((d, i) => (
            <div key={`${d.name}-${i}`} className="col-md-4 col-sm-6">
              <div className="service-card">
                <img src={d.image} alt={d.name} className="service-image" />
                <div className="service-desc">
                  <h3>{d.name}</h3>
                  <p>{d.text}</p>
                  <button
                    onClick={() => handleServiceClick(d.id)}
                    aria-label={`Learn more about ${d.name}`}
                  >
                    Details
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Services;
