import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const WhatsAppButton = () => {
  return (
    <div style={{ position: "fixed", bottom: "20px", right: "20px", textAlign: "center", zIndex: "1000" }}>
      {/* WhatsApp Button */}
      <a
        href="https://wa.me/256766427210?text=Hello! I'm interested in knowing more about TERRY TECNOLOGIES."
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Chat with us on WhatsApp"
        style={{
          backgroundColor: "#25D366",
          color: "white",
          borderRadius: "50%",
          width: "70px", // Increased size
          height: "70px", // Increased size
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "24px",
          textDecoration: "none",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          transition: "background-color 0.3s",
        }}
        onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "#128C7E")}
        onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "#25D366")}
      >
        <FontAwesomeIcon icon={faWhatsapp} style={{ fontSize: "30px" }} />
      </a>

      {/* Styles for small devices */}
      <style jsx>{`
        @media (max-width: 600px) {
          a {
            width: 60px; // Smaller size for small devices
            height: 60px; // Smaller size for small devices
          }
          a > svg {
            font-size: 24px; // Adjust icon size
          }
        }
      `}</style>
    </div>
  );
};

export default WhatsAppButton;
