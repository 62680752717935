import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import './navigation.css';

export const Navigation = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleLinkClick = () => {
    setIsOpen(false);
  };

  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            onClick={handleToggle}
          >
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
          <Link className="navbar-brand page-scroll" to="/">
            <img src="/img/logo/logo.png" alt="Terry Technologies Logo" className="navbar-logo" />
          </Link>
        </div>
        <div
          className={`collapse navbar-collapse ${isOpen ? 'in' : ''}`}
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li>
              <NavLink to="/" exact className="page-scroll" activeClassName="active" onClick={handleLinkClick}>
                Home
              </NavLink>
            </li>
            <li>
              <NavLink to="/services" className="page-scroll" activeClassName="active" onClick={handleLinkClick}>
                Services
              </NavLink>
            </li>
            <li>
              <NavLink to="/whoweare" className="page-scroll" onClick={handleLinkClick}>
                About Us
              </NavLink>
            </li>
            <li>
              <NavLink to="/careers" className="page-scroll" activeClassName="active" onClick={handleLinkClick}>
                Careers
              </NavLink>
            </li>
            <li>
              <NavLink to="/contact" className="page-scroll" activeClassName="active" onClick={handleLinkClick}>
                Contact
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
