import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import './home1.css';

gsap.registerPlugin(ScrollTrigger);

export const Home1 = () => {
  const homeRef = useRef(null);
  const textRef = useRef(null);
  const expertiseRef = useRef(null);
  const whyRef = useRef(null);

  useEffect(() => {
    const home = homeRef.current;
    const text = textRef.current;
    const expertise = expertiseRef.current;
    const why = whyRef.current;

    // Main container animation
    gsap.fromTo(
      home,
      {
        opacity: 0,
        y: 100,
        rotationY: -45,
      },
      {
        opacity: 1,
        y: 0,
        rotationY: 0,
        duration: 1,
        scrollTrigger: {
          trigger: home,
          start: "top 80%",
          end: "bottom 60%",
          scrub: true,
        },
      }
    );

    // Text animation
    gsap.fromTo(
      text,
      { opacity: 0, y: 50 },
      {
        opacity: 1,
        y: 0,
        duration: 1,
        scrollTrigger: {
          trigger: text,
          start: "top 80%",
          end: "bottom 60%",
          scrub: true,
        },
      }
    );

    // Expertise list animation
    gsap.fromTo(
      expertise,
      { opacity: 0, y: 50 },
      {
        opacity: 1,
        y: 0,
        duration: 1,
        scrollTrigger: {
          trigger: expertise,
          start: "top 80%",
          end: "bottom 60%",
          scrub: true,
        },
      }
    );

    // Why Choose Us list animation
    gsap.fromTo(
      why,
      { opacity: 0, y: 50 },
      {
        opacity: 1,
        y: 0,
        duration: 1,
        scrollTrigger: {
          trigger: why,
          start: "top 80%",
          end: "bottom 60%",
          scrub: true,
        },
      }
    );
  }, []);

  return (
    <div id="home1" className="home1-section" ref={homeRef}>
      <video autoPlay muted loop className="background-video">
        <source src="/vid/2.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xs-12 col-md-8">
            <div className="home1-text text-center" ref={textRef}>
              <h2>Digital Transformation of Business</h2>
              <p>Transforming businesses through innovative digital solutions.</p>
              <div className="expertise-and-why">
                <div className="expertise-section" ref={expertiseRef}>
                  <h3>Our Expertise</h3>
                  <div className="list-style">
                    <ul>
                      <li>Advanced Analytics</li>
                      <li>Cloud Solutions</li>
                      <li>Cybersecurity</li>
                      <li>Digital Strategy</li>
                      <li>Enterprise Architecture</li>
                      <li>Mobile App Development</li>
                    </ul>
                  </div>
                </div>
                <div className="why-section" ref={whyRef}>
                  <h3>Why Choose Us?</h3>
                  <div className="list-style">
                    <ul>
                      <li>Proven Track Record</li>
                      <li>Innovative Solutions</li>
                      <li>Customer-Centric Approach</li>
                      <li>Experienced Team</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
